import React from "react";
import PropTypes from "prop-types";
import { BaseOffersTile } from "./base-offer-tile";
import { useLocalizationLookup } from "../../../localization";
export const PlansList = (props) => {
    const { plan = {}, t, stampInfo } = props;
    const { stamp: cmsStamp, monthlyPrice, monthlyPriceStriked, title2, moneyBacktext, isFreeTrail, planType, stamptile } = stampInfo || {};
    const { stamp, marketingValue, subtitle2 } = plan;
    const { showCurrencyInPrefix } = useLocalizationLookup();
    const stampStyle = {
        border: stamp ? "3px solid rgb(252, 232, 96)" : "none",
        borderRadius: "3px",
    };
    const marketingValueDecimal = marketingValue && marketingValue.split(".")[0];
    const marketingValueSubDecimal = marketingValue && marketingValue.split(".")[1];
    return (
        <BaseOffersTile outerDivStyle="free-trial-box" {...props}>
            {({ showCTAButton }) => {
                return (
                    <div className={`offer-plan-tile ${stamp ? "stamp-tile" : ""} ${stamptile ? "stamp-tile" : "tile-button-style"}`} style={stampStyle}>
                        <div className="price-details">
                            {stamp || (cmsStamp && <div className={`stamp ${isFreeTrail ? "stamp-free-trail" : ""}`}>{plan.stamp.altText || cmsStamp}</div>)}
                            <div className={`d-flex flex-column ${stamp ? "mt-n3" : ""}`}>
                                <div className="months">{planType ? planType : null}</div>
                                <div className="price-container">
                                    {monthlyPriceStriked && (
                                        <div className="price striked price-strike dollars strike-through-price">
                                            <s>
                                                {showCurrencyInPrefix && <div className="dollar">{t("currency-symbol")}</div>}
                                                <div className="dollars">{monthlyPriceStriked}</div>
                                            </s>
                                        </div>
                                    )}
                                    {marketingValue && (
                                        <div className="price">
                                            {showCurrencyInPrefix && <div className="dollar">{t("currency-symbol")}</div>}
                                            <div className="dollars">
                                                {monthlyPrice ? (
                                                    monthlyPrice
                                                ) : (
                                                    <>
                                                        {marketingValueDecimal}
                                                        {marketingValueSubDecimal && <span className="dec-amt">.{marketingValueSubDecimal}</span>}
                                                    </>
                                                )}
                                                <span className="term-amt">{t("plan-tenure").replace("month", "mo")}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="title-container">
                                    {(subtitle2 || title2) && (
                                        <div
                                            className="description"
                                            dangerouslySetInnerHTML={{
                                                __html: title2 || subtitle2,
                                            }}
                                        ></div>
                                    )}
                                </div>
                                <div className="cta-container">{showCTAButton()}</div>
                                {/* {(subtitle1 || title1) && (
                                    <div
                                        className="description"
                                        dangerouslySetInnerHTML={{
                                            __html: title1 || subtitle1,
                                        }}
                                    ></div>
                                )} */}
                                {moneyBacktext && (
                                    <div className="mbg mbg-container" data-optly-543ef466-f0b0-4e6d-80e7-76fb1b1e931e="">
                                        <img
                                            src="//img1.beachbodyimages.com/beachbody/image/upload/v1678479707/offers-page/badge-30day-mbg.svg"
                                            alt="30 Day Money Back Guarantee"
                                        />
                                        <p>{moneyBacktext}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            }}
        </BaseOffersTile>
    );
};

PlansList.propTypes = {
    fields: PropTypes.object,
    plan: PropTypes.object,
    t: PropTypes.func,
    stampInfo: PropTypes.object,
};

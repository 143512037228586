import React, { useState } from "react";
import PropTypes from "prop-types";
import { CTAButton } from "../../../common";
import { useSelector } from "react-redux";
import { logProductData } from "../../../web-analytics";
import { offersActions } from "../data/offers-page-action";
import { CHECKOUT_PATH, CREATE_ACCOUNT_PATH, DEFAULT_PRODUCT_CODE, INTERSTITIAL_PAGE } from "../../../constants/urls";
import { triggerConflictModal } from "../../common/util/conflict-modal";
import { OFFERS_PAGE, ERROR_CODE_PROGRAM_ENTITLEMENT, PROGRAM_ENTITLEMENT } from "../../create-account/components/constants";
import { redirectWithParam } from "../../common/util";

export const BaseOffersTile = (props) => {
    const { plan = {}, children, t, disableSelect, setDisableSelect, dispatch, renderinterstitialUpsellPage, clearInterstitial, isAccountCreated } = props;
    const { isDesktopView } = useSelector((state) => state.pageRouter);
    const [showLoader, setLoader] = useState(false);
    const digitalData = useSelector((state) => state.pageRouter.digitalData);
    const { productId, order } = useSelector((state) => state.offers);
    const onAddItemToCartSuccess = async (addedSku) => {
        const page = renderinterstitialUpsellPage(addedSku);
        if (page) {
            redirectWithParam(props.history, `${INTERSTITIAL_PAGE}${page.pageUrl}`);
        } else {
            clearInterstitial();
            const id = sessionStorage.getItem("oid");
            const param = id ? `?oid=${id}` : "";
            const url = isAccountCreated ? `${CHECKOUT_PATH}${param}` : CREATE_ACCOUNT_PATH;
            sessionStorage.removeItem("isBrowserArrow");
            redirectWithParam(props.history, url);
        }
    };
    const onAddItemToCartFailure = (response) => {
        setLoader(false);
        setDisableSelect(false);
        if (response?.formExceptions[0]?.errorCode === ERROR_CODE_PROGRAM_ENTITLEMENT) {
            triggerConflictModal({
                t,
                programEntitlement: response?.formExceptions[0]?.localizedMessage,
                props,
                page: OFFERS_PAGE,
                modalType: PROGRAM_ENTITLEMENT,
                offersList: true,
            });
        }
    };
    const onPlanSelection = (e, plan = {}) => {
        const dataId = e.target.closest("button") && e.target.closest("button").getAttribute("data-id");
        const catalogRefIds = dataId ? dataId : plan.id || "";
        const removalCommerceIds =
            order &&
            order.commerceItems
                .map((item) => {
                    return item.id;
                })
                .join();
        logProductData(productId, catalogRefIds, plan, { ...digitalData, link_label: e?.target?.textContent, orderID: order?.id });
        //logUserAction({ dispatch, payload: { message: "Add Item To Cart" } });
        const productCode = localStorage.getItem("royaltyCode");
        const payload = {
            productId,
            catalogRefIds,
            removalCommerceIds,
            isToUpdateCart: removalCommerceIds ? true : false,
            primaryProductCode: productCode || DEFAULT_PRODUCT_CODE,
        };
        setLoader(true);
        productId &&
            catalogRefIds &&
            dispatch({
                type: offersActions.ADD_ITEM_TO_CART,
                payload,
                callbacks: { success: onAddItemToCartSuccess, failure: onAddItemToCartFailure },
            });
        setDisableSelect(true);
    };
    const showCTAButton = () => {
        return (
            <CTAButton
                buttonName={t("select")}
                icon={showLoader ? "fa-refresh fa-spin" : ""}
                disableButton={disableSelect ? { disabled: "disabled" } : null}
                trackId={productId + ":" + plan.id}
                onButtonClick={(e) => {
                    onPlanSelection(e, plan);
                }}
            />
        );
    };
    return (
        <div key={plan.id} id={plan.id} className="mr-lg-0 mt-5">
            {children({ showCTAButton, isDesktopView })}
        </div>
    );
};

BaseOffersTile.propTypes = {
    plan: PropTypes.object,
    children: PropTypes.func,
    t: PropTypes.func,
    disableSelect: PropTypes.bool,
    setDisableSelect: PropTypes.func,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    renderinterstitialUpsellPage: PropTypes.func,
    clearInterstitial: PropTypes.func,
    isAccountCreated: PropTypes.bool,
};
